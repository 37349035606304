import React from 'react';
import water from '../images/water.png';
import clock from '../images/clock.png';
import book from '../images/book.png';
import study from '../images/study.png';
import styles from '../components/styles/recruit.module.css';

export const EmployeeBenefits = () => {
  const BENEFITS = [
    { title: 'ウォーターサーバー完備', img: water },
    { title: 'フレックスタイム制度', img: clock },
    { title: '書籍購入制度', img: book },
    { title: 'スキルアップ支援', img: study },
  ];

  return (
    <div className={styles.benefits}>
      <ol>
        {BENEFITS.map((benefit) => (
          <li>
            <div className={styles.benefitImage}>
              <img src={benefit.img} alt='' />
            </div>
            <p>{benefit.title}</p>
          </li>
        ))}
      </ol>
    </div>
  );
};
