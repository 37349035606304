import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import styles from '../components/styles/recruit.module.css';

export const PositionsAvailable = () => {
  const data = useStaticQuery(graphql`
    query {
      allContentfulVjcHpRecruitPositionsAvailable(
        filter: { node_locale: { eq: "en-US" } }
      ) {
        edges {
          node {
            title
            occupation {
              json
            }
            jobDescription {
              json
            }
          }
        }
      }
    }
  `);

  const isHiring = () => {
    return !data.allContentfulVjcHpRecruitPositionsAvailable.edges.some(
      (edge) => edge.node.title === '募集なし'
    );
  };

  if (!isHiring()) return <p>現在募集はありません。</p>;

  return (
    <ol>
      <div className={styles.positionsAvailableContainer}>
        {data.allContentfulVjcHpRecruitPositionsAvailable.edges.map(
          (edge, i) => {
            return (
              <li
                key={`${edge.node.id}-${i}`}
                className={styles.positionsAvailable}
              >
                <div className={styles.occupation}>
                  {documentToReactComponents(edge.node.occupation.json)}
                </div>
                <div className={styles.jobDescription}>
                  {documentToReactComponents(edge.node.jobDescription.json)}
                </div>
              </li>
            );
          }
        )}
      </div>
    </ol>
  );
};
