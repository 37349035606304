import React from 'react';
import styles from '../components/styles/recruit.module.css';
import arrow from '../images/arrow.png';

export const SelectionProcess = () => {
  const PROCESS = [
    <p className={styles.firstStep}>
      <span className={styles.noWrap}>「応募はこちら」</span>
      <span className={styles.noWrap}>ボタンよりご応募ください</span>
    </p>,
    '書類選考',
    '面接　１～２回を予定',
    '内定',
    '入社',
  ];

  return (
    <ol className={styles.selectionProcess}>
      <li className={styles.selectionStep}>{PROCESS[0]}</li>
      {PROCESS.slice(1).map((step) => {
        return (
          <>
            <li className={styles.selectionArrow}>
              <img src={arrow} alt='' />
            </li>
            <li className={styles.selectionStep}>
              <p>{step}</p>
            </li>
          </>
        );
      })}
    </ol>
  );
};
