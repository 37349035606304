import React, { useState, useMemo } from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import { EmployeeIntroductionDetail } from '../components/EmployeeIntroductionDetail.jsx';
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, { Navigation, HashNavigation } from 'swiper';
import 'swiper/swiper-bundle.css';
import styles from '../components/styles/recruit.module.css';

SwiperCore.use([Navigation, HashNavigation]);

export const EmployeeIntroduction = () => {
  const data = useStaticQuery(graphql`
    query {
      allContentfulVjcHpRecruitEmployeeIntroduction(
        filter: { node_locale: { eq: "en-US" } }
      ) {
        edges {
          node {
            id
            name
            department
            portrait {
              file {
                url
              }
            }
            question1
            answer1
            question2
            answer2
            question3
            answer3
            question4
            answer4
            question5
            answer5
            question6
            answer6
            question7
            answer7
            question8
            answer8
            question9
            answer9
          }
        }
      }
    }
  `);

  const clientWidth =
    typeof document !== `undefined` ? document.body.clientWidth : null;

  const swiperProps = {
    tag: 'ol',
    navigation: {
      nextEl: '.swiper-button-next',
      prevEl: '.swiper-button-prev',
    },
    slidesPerView: clientWidth < 550 ? 2 : 4,
    slidesPerColumn: 2,
    slidesPerGroup: 1,
    hashNavigation: true,
  };

  const [isProfileShown, setIsProfileShow] = useState(false);
  const [currentSlide, setCurrentSlide] = useState(1);

  const profileItems = useMemo(() => {
    return data.allContentfulVjcHpRecruitEmployeeIntroduction.edges;
  }, [data]);

  const handleClick = async (index) => {
    await setCurrentSlide(index);
    setIsProfileShow(!isProfileShown);
  };

  const handleClose = async () => {
    setIsProfileShow(!isProfileShown);
  };

  if (!isProfileShown) {
    return (
      <Swiper className={styles.swiper} {...swiperProps}>
        {data.allContentfulVjcHpRecruitEmployeeIntroduction.edges.map(
          (edge, index) => (
            <SwiperSlide
              key={edge.node.id}
              tag='li'
              className={styles.profiles}
              data-hash={`slide${index + 1}`}
            >
              <img
                src={edge.node.portrait.file.url}
                alt=''
                role='presentation'
                onClick={() => handleClick(index)}
              />
              <div
                className={styles.profileName}
                role='presentation'
                onClick={() => handleClick(index)}
              >
                <p>{edge.node.department}</p>
                <p>{`${edge.node.name}`}</p>
              </div>
            </SwiperSlide>
          )
        )}
        <div
          className={`swiper-button-prev swiper-button-black ${styles.prev}`}
        />
        <div
          className={`swiper-button-next swiper-button-black ${styles.next}`}
        />
      </Swiper>
    );
  }

  return EmployeeIntroductionDetail(currentSlide, profileItems, handleClose);
};
