import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import styles from '../components/styles/recruit.module.css';

export const ApplicationRequirement = () => {
  const data = useStaticQuery(graphql`
    query {
      allContentfulVjcHpRecruitApplicationRequirements(
        filter: { node_locale: { eq: "en-US" } }
        sort: { fields: order }
      ) {
        edges {
          node {
            title
            description {
              json
            }
          }
        }
      }
    }
  `);

  return (
    <ol>
      {data.allContentfulVjcHpRecruitApplicationRequirements.edges.map(
        (edge, i) => {
          return (
            <li
              key={`${edge.node.id}-${i}`}
              className={styles.applicationRequirement}
            >
              <p className={styles.applicationRequirementTitle}>
                {edge.node.title}
              </p>
              {documentToReactComponents(edge.node.description.json)}
            </li>
          );
        }
      )}
    </ol>
  );
};
