import React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, { Navigation } from 'swiper';
import 'swiper/swiper-bundle.css';
import AnchorLink from 'react-anchor-link-smooth-scroll';
import styles from '../components/styles/recruit.module.css';

SwiperCore.use([Navigation]);

export const EmployeeIntroductionDetail = (
  currentSlide,
  profileItems,
  handleClose
) => {
  return (
    <div>
      <Swiper
        navigation={{
          prevEl: '.swiper-button-prev',
          nextEl: '.swiper-button-next',
        }}
        className={styles.swiperDetail}
        loop
        initialSlide={currentSlide}
      >
        {profileItems.map((profileItem) => (
          <SwiperSlide>
            <div className={styles.profile}>
              <div className={styles.profilePortraitSection}>
                <div className={styles.profilePortrait}>
                  <img src={profileItem.node.portrait.file.url} alt='' />
                  <p>{`${profileItem.node.department}　${profileItem.node.name}`}</p>
                </div>
              </div>
              <ol>
                {Object.keys(profileItem.node)
                  .slice(0, 14)
                  .map((key) => {
                    return (
                      <>
                        {key.includes('question') && (
                          <li className={styles.profileQuestion}>
                            <p>{profileItem.node[key]}</p>
                          </li>
                        )}
                        {key.includes('answer') && (
                          <li className={styles.profileAnswer}>
                            <p>{profileItem.node[key]}</p>
                          </li>
                        )}
                      </>
                    );
                  })}
              </ol>
              <ol>
                {Object.keys(profileItem.node)
                  .slice(14)
                  .map((key) => {
                    return (
                      <>
                        {key.includes('question') && (
                          <li className={styles.profileQuestion}>
                            <p>{profileItem.node[key]}</p>
                          </li>
                        )}
                        {key.includes('answer') && (
                          <li className={styles.profileAnswer}>
                            <p>{profileItem.node[key]}</p>
                          </li>
                        )}
                      </>
                    );
                  })}
              </ol>

              <AnchorLink href='#employeeIntroduction' offset='150'>
                <button
                  onClick={handleClose}
                  text-value='BACK'
                  className={styles.backButton}
                >
                  BACK
                </button>
              </AnchorLink>
            </div>
          </SwiperSlide>
        ))}
        <div
          className={`swiper-button-prev swiper-button-black ${styles.prevPerson}`}
        />
        <div
          className={`swiper-button-next swiper-button-black ${styles.nextPerson}`}
        />
      </Swiper>
    </div>
  );
};
