import React from 'react';
import Layout from '../components/Layout';
import SEO from '../components/Seo';
import { useStaticQuery, graphql } from 'gatsby';
import { ApplyButton } from '../components/ApplyButton.jsx';
import { HeaderImage } from '../components/HeaderImage.jsx';
import { Subheader } from '../components/Subheader.jsx';
import { ToTopButton } from '../components/ToTopButton.jsx';
import { RecruitMessage } from '../components/RecruitMessage.jsx';
import { ApplicationRequirement } from '../components/ApplicationRequirement.jsx';
import { PositionsAvailable } from '../components/PositionsAvailable.jsx';
import { SelectionProcess } from '../components/SelectionProcess.jsx';
import { EmployeeBenefits } from '../components/EmployeeBenefits.jsx';
import { TrainingSystem } from '../components/TrainingSystem.jsx';
import { EmployeeIntroduction } from '../components/EmployeeIntroductions.jsx';
import { RecruitFAQ } from '../components/RecruitFAQ.jsx';
import styles from '../components/styles/recruit.module.css';
import v from '../images/v.png';

const Recruit = () => {
  const data = useStaticQuery(graphql`
    query {
      allContentfulVjcHpRecruitPositionsAvailable(
        filter: { node_locale: { eq: "en-US" } }
      ) {
        edges {
          node {
            title
          }
        }
      }
    }
  `);

  const subheaderProps = {
    subheaderList: [
      { title: '募集要項 ▼', link: '#applicationRequirement' },
      { title: '募集職種 ▼', link: '#positionsAvailable' },
      { title: '選考フロー ▼', link: '#selectionProcess' },
      { title: '福利厚生 ▼', link: '#employeeBenefits' },
      { title: '研修制度 ▼', link: '#trainingSystem' },
      // { title: '社員紹介 ▼', link: '#employeeIntroduction' },
      { title: '採用FAQ ▼', link: '#faq' },
    ],
    style: styles.subheader,
  };

  const recruitContents = [
    {
      boxId: 'applicationRequirement',
      title: '募集要項',
      content: ApplicationRequirement(),
    },
    {
      boxId: 'positionsAvailable',
      title: '募集職種',
      content: PositionsAvailable(),
    },
    {
      boxId: 'selectionProcess',
      title: '選考フロー',
      content: SelectionProcess(),
    },
    {
      boxId: 'employeeBenefits',
      title: '福利厚生',
      content: EmployeeBenefits(),
    },
    { boxId: 'trainingSystem', title: '研修制度', content: TrainingSystem() },
    // {
    //   boxId: 'employeeIntroduction',
    //   title: '社員紹介',
    //   content: EmployeeIntroduction(),
    // },
    { boxId: 'faq', title: '採用FAQ', content: RecruitFAQ() },
  ];

  return (
    <Layout>
      <SEO title='採用情報' description='バークリー・ジャパン株式会社は「ストレスフリーの社会に」を理念に掲げ2019年に設立いたしました。当社では数年後のSaasによるBtoB向けサービスに向けて、技術力の増強や業務を拡大していくための創業メンバーを募集しています。'/>
      <ToTopButton />
      {!data.allContentfulVjcHpRecruitPositionsAvailable.edges.some(
        (edge) => edge.node.title === '募集なし'
      ) && <ApplyButton styles={styles} />}
      <HeaderImage title='RECRUIT' subTitle='採用情報' fileName='recruit.jpg' />
      <Subheader subheaderProps={subheaderProps} />
      <div className={styles.recruitContent}>
        <img
          src={v}
          className={styles.messageImage}
          alt='バークリージャパンロゴ'
        />
        <RecruitMessage />
        {recruitContents.map((recruitContent, i) => (
          <section
            id={recruitContent.boxId}
            className={styles.box}
            key={`recruit-content-${i}`}
          >
            <h1 className={styles.title} text-value={recruitContent.title}>
              {recruitContent.title}
            </h1>
            <div className={styles.boxContentContainer}>
              <section className={styles.boxContent}>
                {recruitContent.content}
              </section>
            </div>
          </section>
        ))}
      </div>
    </Layout>
  );
};

export default Recruit;
