import React, { useEffect, useRef } from 'react';
import styles from '../components/styles/recruit.module.css';

import gsap from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';

export const RecruitMessage = () => {
  const refs = useRef([]);
  refs.current = [];

  useEffect(() => {
    refs.current.forEach((el, index) => {
      gsap.fromTo(
        el,
        { autoAlpha: 0, y: +100 },
        {
          duration: 1,
          autoAlpha: 1,
          y: 0,
          ease: 'power4.out',
          scrollTrigger: {
            id: `section-${index + 1}`,
            trigger: el,
            start: 'top bottom-=80',
            end: 'bottom 100',
            toggleActions: 'play pause complete reverse',
          },
        }
      );
    });
  });

  gsap.registerPlugin(ScrollTrigger);

  const addToRefs = (el) => {
    if (el && !refs.current.includes(el)) {
      refs.current.push(el);
    }
  };

  const MESSAGES = (
    <p className={styles.message} ref={addToRefs}>
      バークリー・ジャパン株式会社は「ストレスフリーの社会に」
      <br />
      を理念に掲げ2019年に設立いたしました。
      <br />
      当社では数年後のSaasによるBtoB向けサービスに向けて、
      <br />
      技術力の増強や業務を拡大していくための創業メンバーを募集しています。
      <br />
      <br />
      あなたにとってストレスとは何でしょうか。
      <br />
      社会生活だけでなくプライベートの時間であっても様々な事象によりストレスが生まれています。
      <br />
      もちろんそんなすべてのストレスをWebサービスだけで解決できるとは考えておりません。
      <br />
      ただ、ほんの少しでもWebサービスを通じて顧客企業引いてはその先にある消費者へのストレスを軽減できるサービスをご提供できたらと考えております。
      <br />
      <br />
      私たちと一緒にストレスを解放するサービスを考えていきませんか。
    </p>
  );

  return <>{MESSAGES}</>;
};
