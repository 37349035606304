import React, { useState } from 'react';
import { Link } from 'gatsby';
import { window } from 'ssr-window';
import styles from '../components/styles/recruit.module.css';
import resumeW from '../images/page-recruit/resume_white.png';
import resumeR from '../images/page-recruit/resume_red.png';

export const ApplyButton = () => {
  const [currentPosition, setCurrentPosition] = useState(
    parseInt(window.pageYOffset)
  );

  const scrollEvent = () => {
    setCurrentPosition(parseInt(window.pageYOffset));
  };

  window.addEventListener('scroll', scrollEvent, {
    passive: true,
  });

  return (
    <Link
      className={
        currentPosition < 20 ? styles.applyButtonHidden : styles.applyButton
      }
      to='/application/'
    >
      <div className={styles.applyButtonContent}>
        <img src={resumeW} alt='応募はこちら' />
        <p>応募はこちら</p>
      </div>
      <div className={styles.applyButtonContentHover}>
        <img src={resumeR} alt='応募はこちら' />
        <p>応募はこちら</p>
      </div>
    </Link>
  );
};
