import React from 'react';
import styles from '../components/styles/recruit.module.css';

export const TrainingSystem = () => {
  const TRAINING_CONTENTS = [
    {
      title: 'Java基礎',
      content: '基礎的なプログラミング技法について習得していきます。',
    },
    {
      title: 'データベース',
      content: 'データベースの基礎と、Javaとの連携について習得します。',
    },
    {
      title: 'サーバーサイド',
      content: 'サーバーサイドのプログラミング技法を習得します。',
    },
    {
      title: 'フレームワーク',
      content: 'フレームワークによるアプリケーション開発を習得します。',
    },
    {
      title: '模擬プロジェクト',
      content:
        'オリジナルWebシステムを作成します。完成後、プレゼンテーションを行い、研修終了です。',
    },
  ];

  return (
    <>
      <div className={styles.trainingPeriod}>
        <div className={styles.firstMonth}>
          <span>1ヶ月目</span>
        </div>
        <div className={styles.secondMonth}>
          <span>2ヶ月目</span>
        </div>
        <ol>
          {TRAINING_CONTENTS.map((content) => (
            <li>
              <div className={styles.curriculum}>
                <p className={styles.training}>{content.title}</p>
                <p className={styles.trainingDescription}>{content.content}</p>
              </div>
            </li>
          ))}
        </ol>
      </div>
    </>
  );
};
