import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import styles from '../components/styles/recruit.module.css';

export const RecruitFAQ = () => {
  const data = useStaticQuery(graphql`
    query {
      allContentfulVjcHpRecruitFaq(
        filter: { node_locale: { eq: "en-US" } }
        sort: { fields: id }
      ) {
        edges {
          node {
            id
            question
            answer
          }
        }
      }
    }
  `);

  return (
    <ol>
      {data.allContentfulVjcHpRecruitFaq.edges.map((edge) => {
        return (
          <li className={styles.faq} key={edge.node.id}>
            <p className={styles.question}>{edge.node.question}</p>
            <p className={styles.answer}>{edge.node.answer}</p>
          </li>
        );
      })}
    </ol>
  );
};
